/*.mapboxgl-popup-close-button { }*/
.mapboxgl-popup-content {
	height: 115px;
	width: 330px;
	margin: 0;
	padding: 0;
	border-radius: 8px;
	box-shadow: 0 8px 20px 0 rgba(10, 36, 99, 0.24);
	background-color: #ffffff;
}

.place-popup {
	margin: 0;
	padding: 0;
}

.vessel-popup>.mapboxgl-popup-content {
	width: 200px !important;
	height: 90px !important;
}

.add-popup>.mapboxgl-popup-content {
	height: 178px;
	width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

/*.mapboxgl-popup-content-wrapper { }*/
