@font-face {
	font-family: "Decimamono";
	src: url(../../Fonts/TipografiaRamis\ -\ DecimaMonoPro.otf);
}

@font-face {
	font-family: "Dromon-light";
	src: url(../../Fonts/cf_dromon_lg-webfont.ttf);
}

/** TO DISABLE THE DATE PICKER **/
#date-container {
	pointer-events: none;
}

#country-selector input {
	top: 0 !important;
	opacity: 1 !important;
	left: 10px !important;
	border: none !important;
}

.MuiPickersToolbar-toolbar {
	background-color: #0078ff !important;
}

.css-w6vhv5 i,
.css-ponpwz i {
	display: none !important;
	visibility: hidden !important;
	height: 0;
	width: 0;
}
.css-ponpwz,
.css-w6vhv5 {
	display: none !important;
	visibility: hidden !important;
	height: 0;
	width: 0;
}
.rdrDefinedRangesWrapper,
.rdrDateDisplayWrapper,
.rdrMonthName {
	display: none;
}
/*
.rdrInRange {
	background-color: rgb(141, 152, 154);
} */

/* .rdrDayNumber span {
	color: #222c4b;
} */

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
	background-color: transparent;
}

body,
html {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}

* {
	box-sizing: border-box;
}

.hour-tag span {
	font-family: "Decimamono";
}

.introjs-helperLayer {
	box-shadow: #0078ff, #21212180 !important;
	/* margin-right: -15px !important; */
}
/* .introjs-tooltip {
	bottom: 0 !important;
} */

/* .hSFtmk {
	bottom: 0 !important;
	height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Decimamono", sans-serif;
	color: #2b4b80;
	margin-top: 0px;
	font-style: normal;
	font-weight: 300;
	text-transform: normal;
}

dl,
ol,
ul {
	margin-top: 0;
	margin-bottom: 1rem;
	list-style: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.deci {
	font-family: "Decimamono";
}

.auth-texts {
	font-family: "Dromon-light";
}

.dromon-l {
	font-family: "Dromon-light";
}

.step-body {
	min-height: 75vh;
}

input.inp-focus-BP:focus {
	outline-color: #0078ff !important;
}

body {
	font-family: "Decimamono";
	margin: 0;
	padding: 0;
}

.container-fluid {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.navbar {
	z-index: 5;
}

.scrollable-container {
	height: 100vh;
	width: 100vw;
	overflow-x: hidden;
	overflow-y: scroll;
}

.scrollable-container::-webkit-scrollbar {
	width: 10px;
}

.scrollable-container::-webkit-scrollbar-track {
	background: #fff;
	border-radius: 5px;
}

.scrollable-container::-webkit-scrollbar-thumb {
	background: #8f96a7;
	border-radius: 5px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
	background: #222c4b;
}

#home-container .home-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 42;
}

#home-container .home-body {
	padding: 75px 35px 0 35px;
}

#home-container .card-landing {
	min-height: 42.5vh;
	border-radius: 20px;
	padding: 35px;
}

#home-footer-wrapper {
	padding: 75px 35px;
	width: 100%;
}

#home-footer-wrapper .footer-logo {
	margin-bottom: 24px;
}

#home-footer-wrapper .info1 {
	font-family: "Dromon-light";
	font-size: 12px;
	font-weight: 300;
	color: #8f96a7;
}

#home-footer-wrapper .footer-area .footer-social a,
#home-footer-wrapper .footer-area .footer-languages a {
	width: 25px;
	height: 40px;
	font-size: 14px;
	border: 1px solid transparent;
	background: transparent;
	display: inline-block;
	line-height: 40px;
	text-align: center;
	color: #222c4b;
	margin-right: 5px;
	transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
	text-decoration: none;
}

#home-footer-wrapper .footer-area .footer-social a:hover,
#home-footer-wrapper .footer-area .footer-languages a:hover {
	color: #0078ff;
}

#home-footer-wrapper .footer-tittle > h4 {
	font-size: 14px;
	color: #222c4b;
	margin-bottom: 25px;
}

#home-footer-wrapper .footer-tittle ul {
	list-style: none;
	margin: 0;
	padding: 0;
	font-size: 12px;
}

#home-footer-wrapper .footer-tittle ul > li {
	display: flex;
	align-items: center;
}

#home-footer-wrapper .footer-tittle ul a {
	padding: 5px 0;
	color: #8f96a7;
	transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

#home-footer-wrapper .footer-tittle ul a:hover {
	color: #0078ff;
	padding-left: 5px;
	text-decoration: none;
}

#CustomDialog .MuiDialog-paper {
	overflow: initial;
}

.coverage::before {
	background-image: url(../img/shapes/wave-dots-before.png);
	position: absolute;
	content: "";
	top: -187px;
	z-index: -1;
	background-repeat: no-repeat;
	left: 0px;
	width: 243px;
	height: 482px;
}

.coverage::after {
	background-image: url(../img/shapes/wave_dots_after_.png);
	position: absolute;
	content: "";
	z-index: -1;
	background-repeat: no-repeat;
	right: 0px;
	width: 351px;
	height: 461px;
	bottom: 50px;
}

.reading-section p {
	font-family: "Dromon-light", sans-serif;
	color: #8f96a7;
	font-size: 16px;
	line-height: 30px;
	margin-bottom: 15px;
	font-weight: normal;
	margin-top: 0;
}

.loading-view > div {
	font-family: "Decimamono";
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 100px;
	box-shadow: 0 0 15px 0px #0078ff16;
	border-radius: 50px;
	animation: blink_ 2s linear infinite alternate;
}

.loading-view > div > img {
	width: 70px;
}

.loading-view > div:before,
.loading-view > div:after {
	position: absolute;
	content: "";
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: solid 3px #0078ff;
	border-radius: 100%;
}

.loading-view > div:before {
	animation: ripple 2s linear infinite;
}

.loading-view > div:after {
	animation: ripple 2s 1s linear infinite;
}

@keyframes ripple {
	to {
		transform: scale(2);
		opacity: 0;
	}
}

@keyframes blink_ {
	to {
		opacity: 0.3;
	}
}

@media only screen and (max-width: 425px) {
	#home-container .home-body {
		padding: 75px 20px 0 20px;
	}

	#home-container .card-landing {
		padding: 35px 20px;
	}

	#home-footer-wrapper {
		padding: 75px 20px;
	}
}
