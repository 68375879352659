.map-container {
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
}

#map-searchbox {
	position: relative;
	top: 0;
	left: 0;
	z-index: 4;
	max-width: 415px;
}

#map-searchbox .MuiTextField-root > .MuiOutlinedInput-root {
	z-index: 1;
	font-family: inherit;
	width: 290px;
	height: 48px;
	border-radius: 24px;
	border: none;
	background-color: #ffffff;
	color: #8f96a7;
	-webkit-box-shadow: 0 0 6px 0 rgba(34, 44, 75, 0.2);
	-moz-box-shadow: 0 0 6px 0 rgba(34, 44, 75, 0.2);
	box-shadow: 0 0 6px 0 rgba(34, 44, 75, 0.2);
	transition: all 0.5s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.mapboxgl-ctrl-group:not(:empty) {
	box-shadow: 0 0 6px 0 rgb(34 44 75 / 20%) !important;
}

#map-searchbox .MuiTextField-root > .MuiOutlinedInput-root.focused {
	width: 340px;
}

#map-searchbox ul {
	background: #fff;
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.mapboxgl-ctrl-bottom-left {
	z-index: 0;
}

.mapboxgl-ctrl-top-right {
	bottom: 80px !important;
}

.custom-weather-toggle-btn {
	position: absolute;
	z-index: 0;
	bottom: 78px !important;
	right: 38px !important;
	height: 48px;
	width: 48px;
	box-shadow: 0 0 6px 0 rgba(34, 44, 75, 0.2);
}

.custom-location-btn {
	position: absolute;
	z-index: 0;
	bottom: 240px !important;
	right: 40px !important;
	height: 48px;
	width: 48px;
	background-color: transparent !important;
}
