@font-face {
  font-family: "Decimamono";
  src: url(../../Fonts/TipografiaRamis\ -\ DecimaMonoPro.otf);
}

.wrapper-coast-view {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 30;
  overflow: hidden;
  background-color: #fff;
}

.header {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.18);
  background-color: rgba(0, 0, 0, 0.48);
  color: #fff;
  z-index: 41;
}

.auth-container {
  overflow-y: scroll;
  -ms-overflow-style: none;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 120, 255, 0.8);
}

.header,
.header-home {
  position: absolute;
  font-family: "Decimamono";
  top: 0;
  height: 56px;
  width: 100vw;
  box-shadow: 0 1px 2px 0 rgba(34, 44, 75, 0.2);
  padding: 0px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-home {
  background-color: rgb(255, 255, 255);
  color: #000;
  z-index: 20 !important;
}

.bookmark-tag {
  position: absolute;
  font-size: 10px !important;
  left: 18px;
  width: 19px;
  height: 19px;
  border-radius: 18px;
	margin-left: -5px;
  object-fit: contain;
  border: solid 1px #ffffff;
  background-color:#0078ff;
	top: 50%;
	transform: translateY(-50%);
  color: white;
}

.breadCrumb {
  position: absolute;
  top: 90px;
  left: 20px;
  text-shadow: 0 2px 2px #8f96a7;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  color: #ffffff;
}

.wrapper-map {
  width: 120px;
  height: 105px;
  position: absolute;
  top: 90px;
  right: 20px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  box-shadow: 0 0 2px 0 #222c4b;
  background-color: #ffffff;
  overflow: hidden;
}

#mapContainer.map {
  height: 71px;
  width: 100%;
  border-radius: 10px;
}

.marker {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #0078ff;
  z-index: 40;
}

.floatIconBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  opacity: 0.9;
  font-size: 16px;
  margin: 0;
  padding: 0;
  border-radius: 100px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  overflow: hidden;
}

.floatIconBtn:hover {
  opacity: 1;
}

.rounded-btn {
  height: 40px;
  border-radius: 20px !important;
  box-shadow: 0 0 2px 0 rgba(34, 44, 75, 0.2);
  font-size: 16px;
  text-transform: initial !important;
}

.rounded-btn-primary {
  background-color: #0078ff !important;
  color: #ffffff !important;
}

.rounded-btn-light {
  background-color: #ffffff !important;
  color: #0a2463 !important;
}

.rounded-btn-dark {
  box-shadow: none !important;
  background-color: rgba(17, 17, 17, 0.3) !important;
  -webkit-backdrop-filter: blur(4px) !important;
  backdrop-filter: blur(4px) !important;
  color: #ffffff !important;
}

.actions-btn,
.actions-places {
  position: absolute;
  bottom: 40px;
  z-index: 40;
  margin: 0 !important;
  padding: 0 !important;
}

.actions-btn {
  left: 20px;
}

.actions-places {
  list-style: none;
  right: 20px;
  width: 200px;
}

#imgCoastView {
	object-fit: cover;
}

@media only screen and (max-width: 1100px) {
	#imgCoastView {
		object-fit: contain;
	}
}
